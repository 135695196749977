import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './mini_tournament_info.css';

function Mini_tournament_info() {


    return (

        <header className="App-header">
            <div className="tournament_rule">
                {/* <h1>제 2회 미니 토너먼트 룰</h1>
                <h2> 전체 공통 룰 적용 </h2>
                <ul>
                    <li> 컨시드는 본인 퍼터 길이 하나 입니다. </li>
                    <li>멀리건은 첫홀 첫드라이버 미스시에만 있습니다</li>

                    <li>티샷이 OB(흰색 말뚝)가 나면 공이 나간 자리 근처에 공을 드랍하고 세번째 샷으로 간주하고 칩니다(로컬룰 적용) </li>

                    <li> 샷을 한 공이 디봇 자국에 들어간 경우는 공을 빼서 근처에 드랍  후 다음 샷을 할수 있도록 합니다.
                    <br></br>또한 샷을 한 후 공이 나무 뿌리나 자갈 등등 클럽에 손상을 줄수 있는 곳에 놓여 있으면 벌타없이 한 클럽 이내에 드랍 후 다음 샷을 할 수 있도록 합니다.</li>

                    <li>인공 장애물로 인해서 본인 스탠스 또는 샷에 방해를 받을 시 무벌타로 한 클럽 이내 공을 드랍하고 칩니다.</li>

                    <li>벙커에 공이 빠진 상황에서 벙커 모래가 많이 젖어서 샷이 힘든 경우 땅을 한번 갈아서  샷을 할수 있도록 합니다. 물이 있어서 샷을 못할시 물기가 없는 모래 쪽으로 옴기고 칩니다. 벙커 전체가 물에 잠긴 상황은 밖으로 빼내서 무벌타 드랍 후 칩니다.
                    <br></br>(단,같이 라운딩 하시는 분들께 꼭 벙커 상황을 알리시고 동의를 구하신 다음 진행합니다)</li>

                    <li>패널티 지역(빨간색 말뚝)에 공이 빠졌을시 샷이 가능 하면 벌타 없이 그대로 치셔도 됩니다. 
                    <br></br>샷이 불가능 하다 판단시 공이 페널티 지역으로 들어간 지점에서 두 클럽 이내에 공을 드랍 하시고 1벌타 적용 후 다음 샷을 진행 하시면 됩니다. </li>

                    여기 계신 모든 멤버들이 조금이라도 더 좋은 스코어를 내실 수 있도록 로컬룰로 수정을 하였습니다.
                </ul> */}
            </div>
        </header>
    );
}

export default Mini_tournament_info;
