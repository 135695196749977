import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  Golf_101_list from './Golf_101/golf_101_list';
import Mini_tournament_info from './Mini_tournament/mini_tournament_info';
import LandingPage from './LandingPage/landingPage';


function App() {
  // const navigate = useNavigate();
  // const onClickGolf101 = () => navigate('/golf_101_list', { replace: true })

  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/" element ={<LandingPage />} />
      <Route path="/golf_101_list" element={ <Golf_101_list /> } />
      <Route path="/mini_tournament_info" element={ <Mini_tournament_info /> } />

        </Routes>
      </BrowserRouter>

    </div>
  );
}


export default App;
